import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { RequestService } from '../request/request.service';
import { StorageService } from '../storage/storage.service';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private storage: StorageService, private request: RequestService, private config: ConfigService) {
  }
  public wx = "/wx/v1/";

  
  //文件上传的接口：  
  public uploadUrl = this.config.uploadDomain + `${this.wx}FileUpload`;

  //管理员登录  AdminLoginout
  public adminUserLogin = (params) => this.request.post(`${this.wx}AdminUserLogin`, params);

  //获取用户的基本信息：
  public getOpenidgzh = () => this.request.get(`${this.wx}getOpenidgzh`);

  //退出登录   
  public adminLoginout = () => this.request.get(`${this.wx}AdminLoginout`);


  //咨询列表：
  public ddlist = (params) => this.request.get(`${this.wx}ddlist`, params);
  //完成确定按钮：
  public wancheng = (params) => this.request.get(`${this.wx}wancheng`, params);

  // 专家管理
  //列表：  
  public fanganList = () => this.request.get(`${this.wx}fanganList`);
  //新增
  public fanganAdd = (params) => this.request.post(`${this.wx}fanganAdd`, params);
  //详情：
  public fanganInfo = (params) => this.request.get(`${this.wx}fanganInfo/${params['id']}`);
  //编辑
  public fanganEdit = (params) => this.request.post(`${this.wx}fanganEdit/${params['id']}`, params);

  //删除 
  public fanganDel = (params) => this.request.get(`${this.wx}fanganDel/${params['id']}`);

  // 专家管理
  //列表：  
  public zproductList = () => this.request.get(`${this.wx}zproductList`);
  //新增
  public zproductAdd = (params) => this.request.post(`${this.wx}zproductAdd`, params);
  //详情：
  public zproductInfo = (params) => this.request.get(`${this.wx}zproductInfo/${params['id']}`);
  //编辑
  public zproductEdit = (params) => this.request.post(`${this.wx}zproductEdit/${params['id']}`, params);

  //删除 
  public zproductDel = (params) => this.request.get(`${this.wx}zproductDel/${params['id']}`);


  //咨询老师(员工)管理：
  //列表： 
  public staffList = () => this.request.get(`${this.wx}staffList`);
  //员工信息
  public staffInfo = (params) => this.request.get(`${this.wx}staffInfo/${params['id']}`, params);
  //添加
  public staffAdd = (params) => this.request.post(`${this.wx}staffAdd`, params);
  //编辑
  public staffEdit = (params) => this.request.post(`${this.wx}staffEdit/${params['id']}`, params);
  //删除
  public staffDel = (params) => this.request.get(`${this.wx}staffDel/${params['id']}`, params);

  ///// 地区管理
  //省市获取： 
  public regionadList = (params) => this.request.get(`${this.wx}regionadList`, params);

  // 地区列表 level	否	1 地区级别，省级1，市级2  parent_id	否	1 所属上级ID
  public regionList = (params) => this.request.get(`${this.wx}regionList`, params);
  // 地区信息
  public regionInfo = (params) => this.request.get(`${this.wx}regionInfo/${params['id']}`);
  // 编辑社平工资
  public wagesEdit = (params) => this.request.post(`${this.wx}wagesEdit/${params['id']}`, params);

  ///// 社保缴费管理
  // 新增社保缴费
  public sipayAdd = (params) => this.request.post(`${this.wx}sipayAdd`, params);
  // 编辑社保缴费
  public sipayEdit = (params) => this.request.post(`${this.wx}sipayEdit/${params['id']}`);
  // 地区社保缴费列表
  public sipayList = (params) => this.request.get(`${this.wx}sipayList`, params);
  // 获取地区缴费信息
  public sipayInfo = (params) => this.request.get(`${this.wx}sipayInfo/${params['id']}`, params);

  // 文章管理
  public adnewsList = (params) => this.request.post(`${this.wx}adnewsList`, params);

  // 文章详情
  public newsInfo = (params) => this.request.get(`${this.wx}newsInfo/${params['id']}`, params);
  // 新增文章
  public newsAdd = (params) => this.request.post(`${this.wx}newsAdd`, params);
  // 编辑文章
  public newsEdit = (params) => this.request.post(`${this.wx}newsEdit/${params['id']}`, params);
  // 删除文章
  public newsDel = (params) => this.request.get(`${this.wx}newsDel/${params['id']}`);


  /////// 文章分类管理
  // 分类列表
  public newscategoryList = (params) => this.request.get(`${this.wx}newscategoryList`, params);

  // 分类详情
  public newscategoryInfo = (params) => this.request.get(`${this.wx}newscategoryInfo/${params['id']}`, params);
  // 新增分类
  public newscategoryAdd = (params) => this.request.post(`${this.wx}newscategoryAdd`, params);
  // 编辑分类
  public newscategoryEdit = (params) => this.request.post(`${this.wx}newscategoryEdit/${params['id']}`, params);
  // 删除分类 
  public newscategoryDel = (params) => this.request.get(`${this.wx}newscategoryDel/${params['id']}`);



  /////// 职工社保缴费查询
  // 列表页
  public workerspayList = (params) => this.request.get(`${this.wx}workerspayList`, params);

  // 添加职工社保缴费记录
  public workerspayAdd = (params) => this.request.post(`${this.wx}workerspayAdd`, params);

  // 编辑职工社保缴费记录
  public workerspayEdit = (params) => this.request.post(`${this.wx}workerspayEdit/${params['id']}`, params);

  // 职工社保缴费详情
  public workerspayInfo = (params) => this.request.get(`${this.wx}workerspayInfo/${params['id']}`, params);

  // 删除职工社保缴费记录
  public workerspayDel = (params) => this.request.get(`${this.wx}workerspayDel/${params['id']}`, params);

  /////// 灵活用工社保缴费查询
  // 灵活用工列表页
  public flexiblejobpayList = (params) => this.request.get(`${this.wx}flexiblejobpayList`, params);

  // 添加灵活用工
  public flexiblejobpayAdd = (params) => this.request.post(`${this.wx}flexiblejobpayAdd`, params);

  // 编辑灵活用工
  public flexiblejobpayEdit = (params) => this.request.post(`${this.wx}flexiblejobpayEdit/${params['id']}`, params);

  // 详情灵活用工
  public flexiblejobpayInfo = (params) => this.request.get(`${this.wx}flexiblejobpayInfo/${params['id']}`, params);

  // 删除灵活用工
  public flexiblejobpayDel = (params) => this.request.get(`${this.wx}flexiblejobpayDel/${params['id']}`, params);

  /////// 居民社保缴费查询
  // 列表居民社保
  public residentpayList = (params) => this.request.get(`${this.wx}ResidentpayList`, params);

  // 添加居民社保
  public residentpayAdd = (params) => this.request.post(`${this.wx}ResidentpayAdd`, params);

  // 编辑居民社保 
  public residentpayEdit = (params) => this.request.post(`${this.wx}ResidentpayEdit/${params['id']}`, params);

  // 详情居民社保
  public residentpayInfo = (params) => this.request.get(`${this.wx}ResidentpayInfo/${params['id']}`, params);

  // 删除居民社保
  public residentpayDel = (params) => this.request.get(`${this.wx}ResidentpayDel/${params['id']}`, params);

  //咨询订单 
  // 列表
  public zorderList = (params) => this.request.get(`${this.wx}zorderList`, params);

  // 答题确认完成订单
  public zorderComplete = (params) => this.request.post(`${this.wx}zorderComplete/${params['id']}`, params);

  //接单： 
  public adminJiedan = (params) => this.request.get(`${this.wx}adminJiedan/${params['id']}`, params);

  // 公众号员工抢单
  public qiangDan = (params) => this.request.get(`${this.wx}qiangDan/${params['id']}`, params);

  //退回接单  
  public zorderTuidan = (params) => this.request.post(`${this.wx}zorderTuidan/${params['id']}`, params);


  //微信退款接口/金桥传媒_admin   wxRefundJq  wxRefundFst
  public wxRefund = (params) => this.request.post(`${this.wx}wxRefundFst`, params);

  //修改密码：
  public changePassWord = (params) => this.request.post(`${this.wx}changePassWord`, params);
  //员工抢单排行榜
  public adminRanking = () => this.request.get(`${this.wx}adminRanking`);

}
