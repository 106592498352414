import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor() { }
  // public mainDomain='http://192.168.1.11';
  public mainDomain='https://api.qxb666.com';
  public uploadDomain='https://api.qxb666.com';
}
