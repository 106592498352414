import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-authorization',
  templateUrl: './authorization.component.html',
  styleUrls: ['./authorization.component.scss']
})
export class AuthorizationComponent implements OnInit {

  constructor(public storage: StorageService,public router: Router) {
    if (this.storage.get('loginState')) {
      console.log("登录~~");
      this.router.navigateByUrl('/order_manage/order_list');
    }
   }

  ngOnInit(): void {
  }

}
