import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  static get(key:any) {
    // console.log(key);
    let temp:any=localStorage.getItem(key);
    return JSON.parse(temp);
    // return JSON.parse("");
  }
  constructor() { }
  set(key:any, value:any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  get(key:any) {
    // console.log(key);
    // let temp:String=localStorage.getItem(key);
    let temp:any=localStorage.getItem(key);
    return JSON.parse(temp);
   
  }
  remove(key:any) {
    localStorage.removeItem(key);
  }
  getCookie(cname:any) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim();
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
  }
}
