<div class="am-demo-page" style="padding-bottom: 15px;">
  <div class="am-list">
    <div class="am-list-header">账号登录</div>
    <div class="am-list-body">
      <InputItem [placeholder]="'请输入账号'" [(ngModel)]="loginname" [content]="'账号'">
      </InputItem>
      <InputItem [type]="'password'" [placeholder]="'请输入密码'" [(ngModel)]="password" [content]="'密码'"></InputItem>
    </div> 
    <WingBlank style="margin-top: 20px;">
      <div Button [type]="'primary'" (onClick)="submitForm()">登录</div>
    </WingBlank>
  </div>
</div>

<!-- <a href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1352431249141eb8&redirect_uri=https://api.qxb666.com/wx/v1/getOpenidgzh&response_type=code&scope=snsapi_userinfo&state=1&connect_redirect=1#wechat_redirect">去授权</a> -->