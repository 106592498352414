import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { StorageService } from '../../services/storage/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginname;
  password;
  gopen_id;
  constructor(private api: ApiService, public storage: StorageService,public router: Router,private http: HttpClient, public route: ActivatedRoute) {
    let accoutInfo = this.storage.get("accoutInfo");
    if (!!accoutInfo) {
      this.loginname=accoutInfo['loginname'];
      this.password=accoutInfo['password'];
    }
    this.gopen_id = this.route.snapshot.queryParams['gopen_id'];
    console.log(this.gopen_id);
  }
  ngOnInit(){

    if (this.storage.get('loginState')) {
      console.log("登录~~");
      this.router.navigateByUrl('/order_manage/order_list');
    }
  } 
  getToken() { 
    return new HttpHeaders().set("Authorization", !!this.storage.get('Authorization') ? this.storage.get('Authorization') : "");
    // return new HttpHeaders();
  }
  submitForm() {
    let params={
      loginname:this.loginname,
      password:this.password,
      gopen_id:this.gopen_id
    };
    console.log(params);
    // return;
    this.api.adminUserLogin(params).then(res => {
      console.log(res);
      this.storage.set('accoutInfo', params);
      this.storage.set('loginState', true);
      this.storage.set('userinfo', res);
      this.storage.set('Authorization', res['token']);
      //登录成功，获取用户的基本信息：  https://api.qxb666.com/wx/v1/getOpenidgzh
      let options = { 
        params: params,
        headers: this.getToken()
      }; 
      this.router.navigateByUrl('/order_manage/order_list');
    });
  }

}
