import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizationComponent } from './pages/authorization/authorization.component';
import { LoginComponent } from './pages/login/login.component';

const routes: Routes = [
  //授权页面：
  { path: 'authorization', component: AuthorizationComponent },
  { path: 'login', component: LoginComponent },
  //订单管理：
  { path: 'order_manage', loadChildren: () => import('../app/module/order-manage/order-manage.module').then(m => m.OrderManageModule) },
  { path: '**', redirectTo: 'authorization' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
